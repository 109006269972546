import { createRouter, createWebHistory } from 'vue-router'
import store from 'nette-base-module/js/store'
import { useMediaQuery } from '@vueuse/core'

const isMobileDevice = useMediaQuery('(max-width: 1023px)') // <= sm
//const preloader = document.querySelector('#VuePreloader')

const BackLayout = () => import('@/backapp/views/Layout.vue')
const FrontLayout = () => {
  return window?.netteInitialState?.presenter?.editable
    ? import('@/frontapp/views/LayoutBack.vue')
    : import(/* webpackPreload: true */ '@/frontapp/views/Layout.vue')
}

const routes = [
  {
    path: '/admin/:pathMatch(.*)*',
    component: BackLayout
  },
  {
    path: '/:pathMatch(.*)*',
    component: FrontLayout
  }
]

// create vue router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && /store-anchor-\d+/.test(to.hash)) {
      window.scrollTo({
        top: 0
      })

      const element = document.querySelector(to.hash)
      if (element) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              el: to.hash,
              behavior: 'smooth',
              top: getComputedStyle(document.documentElement).getPropertyValue('--HeaderHeight')
            })
          }, 500)
        })
      }
      return { top: 0, left: 0 }
    } else if (to.hash && to.hash.includes('letter')) {
      console.log('scrollBehavior - 2')
      // nothing, je fakt zajimave, ze tento soubor VUE cli nezachytiti a musim cli vypnout/zapnout, proc proboha?
      // nechceme vykonavat zadnou akci, router a scroll to nici, proc proboha?
    } else if (to.hash && !to.hash.includes('letter')) {
      // return {
      //   el: to.hash,
      //   top: isMobileDevice.value ? 57 : 0
      // }

      console.log('scrollBehavior - 3')

      //smooth scroll to element with id
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: isMobileDevice.value ? 57 : 0
          })
        }, 500)
      })
    } else if (savedPosition) {
      console.log('scrollBehavior - 4')
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({
            top: savedPosition.top
          })
        }, 100)
      })

      //return savedPosition // if navigate back, forward -> use last known scroll position
    } else if (to.path !== from.path) {
      // scroll to top
      return { top: 0, left: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // console.log('preloader start', preloader)

  if (from.path !== to.path) {
    document.documentElement.classList.add('page-enter-active')
    document.documentElement.classList.add('page-enter-from')
  }

  if (store.state?.menu?.openMenu) {
    store.dispatch('menu/closeMenu')
  }

  if (store.state?.modalSearch?.show) {
    //console.log('modalSearch', store.state.modalSearch)
    store.dispatch('modalSearch/close')
  }

  const delaySetOnMenu = document.querySelector('.DelaySet')
  if (delaySetOnMenu) {
    delaySetOnMenu.classList.remove('DelaySet')
  }

  next()
})

router.afterEach(() => {
  //console.log('preloader end', preloader)

  // back in browser is a huge problem, we need to remove these classes if exists ...

  setTimeout(() => {
    document.body.classList.remove('MenuHoverDelay__HasSubmenu')
    document.documentElement.classList.remove('Locked', 'TinyModalOpened', 'MenuOpened')
  }, 1)

  document.documentElement.classList.remove('page-enter-from')
})

export default router
